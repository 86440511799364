/* global Component */
class conditionalFilterComponent extends Component {

    static name() {
        return "conditionalFilterComponent";
    }

    static componentName() {
        return "conditionalFilterComponent";
    }

    getProps() {
        return ['filterField'];
    }

    data() {
        return {
            display:true,
            isMobile: $(window).width() <= 560,
            renderView:false,
            filterToApply:new Map(),
            collections:[],
            inView:0,
            orderBy:'label',
            orderDirection:'asc'
        };
    }

    getWatch() {
        return {
            '$store.state.itemsFullLoader':function (){
                if(this.$store.state.itemsFullLoader) {
                    this.generateFilters();
                }
            },
            '$store.state.itemUpdate': 'calculateResults',
            '$route': 'generateFilters',
            '$store.state.itemTimeFlag':'generateFilters',
        };
    }

    mounted() {
        return function () {
            try {
                let self = this;
                this.generateFilters();
                this.display = !this.isMobile;
                $(window).resize(function () {
                    self.isMobile = $(window).width() <= 560;
                    self.display = !self.isMobile;
                });
            }
            catch (error){
                console.error(error);
            }
        };
    }

    getMethods() {
        return {
            fetchData:this.fetchData,
            resetCountFilters:this.resetCountFilters,
            calculateResults: this.calculateResults,
            generateFilters:this.generateFilters,
            getfilterLabel:this.getfilterLabel,
            getItemValue:this.getItemValue,
            checkedObserve:this.checkedObserve
        };
    }

    fetchData(filter,value) {
        this.renderView = !this.renderView;
        this.calculateResults();
    }

    resetCountFilters(){
        this.inView = 0;
        for(let filter of this.collections) {
            filter.itemCount = 0;
        }
    }

    calculateResults(){

        let items = this.$store.getters.getItemsVisibles; //this.$store.getters.getItems;
        this.resetCountFilters();
        let inView = 0;
        for(let filter of this.collections) {
            for (let x of items.filter((e) => e.visible == true)) {
                try {
                    let isVisible;
                    if(filter.value)
                        isVisible = filter.value == x[filter.filterField];
                    else
                        isVisible = x[filter.filterField] != true;

                    if (isVisible) {
                        filter.itemCount = filter.itemCount + 1;
                        inView = inView + 1;
                    }
                    filter.active = this.$store.state.filters.indexOf(filter.name) !== -1;
                }
                catch (err) {
                    // eslint-disable-next-line no-console
                    console.log(err, filter, x);
                }
            }
        }
        this.inView = inView;
    }

    generateFilters(){
        let collections = [];
        for(let value of [true,false]) {
            let filter = {};
            filter.name = `conditional-${this.filterField.ItemField}-${value}`;  //uuidv4();
            if(value)
                filter.condition = `x.${this.filterField.ItemField} == ${value}`;
            else
                filter.condition = `x.${this.filterField.ItemField} != true`;

            filter.result = true;
            filter.type = 'multivalue';
            filter.label = value ? this.tr("Yes") : this.tr("No");
            filter.value = value;
            filter.filterField = this.filterField.ItemField;
            filter.itemCount = 0;
            filter.hiddens = new Map();
            filter.active = false;
            collections.push(filter);
        }
        this.collections = collections;
        this.calculateResults();
    }

    getfilterLabel(item,fieldName){
        if(item.fields)
            item = item.fields;
        if(fieldName + '_format' in item)
            if(item[fieldName + '_format'])
                return  item[fieldName + '_format'];
        return item[fieldName];
    }

    getItemValue(item,fieldName){
        if(item.fields)
            item = item.fields;
        return item[fieldName];
    }

    checkedObserve(filter){
        filter.active = true & filter.active;
        this.$store.dispatch('addFilter',filter);
    }

    getTemplate() {
        return `<div class="filter multivalueFilter" v-show="inView>0">
                    <p class="filter-title" @click="display = !display">
                        {{tr(filterField.ItemField)}}
                        <span v-show="display" class="right"><i class="icon fas fa-minus"></i></span>
                        <span v-show="!display" class="right"><i class="icon fas fa-plus"></i></span>
                    </p>
                    <section class="filter-options" :class="{'visible-filter': display}" @click="display = !isMobile">
                        <ul class="unstyled">
                            <template v-for="value of collections">
                                <li :key="'li'+value.name" v-if="value.itemCount>0">
                                    <div class="custom-control custom-checkbox">
                                        <input :key="'input-'+filterField.ItemField+'-'+value.name" class="custom-control-input" type="checkbox" v-model="value.active" :id="'input-'+filterField.ItemField+'-'+value.name" @click="checkedObserve(value)" />
                                        <label :for="'input-'+filterField.ItemField+'-'+value.name" class="custom-control-label">{{value.label}} ({{value.itemCount}})</label>
                                    </div>
                                </li>
                            </template>
                        </ul>
                    </section>
               </div>`;
    }
}

conditionalFilterComponent.registerComponent();